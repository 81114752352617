@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap");
html,
body {
    font-family: "Ubuntu", sans-serif;
    color: #1d1d1d;
    font-size: 15px;
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.container {
    max-width: 980px;
    padding: 0 30px;
}

.Status__WaitingForPayment {
    display: flex;
}

.Status__loading span {
    animation: typing 1s linear infinite;
}

.Status__loading span:nth-child(2) {
    animation-delay: -0.9s;
}

.Status__loading span:nth-child(3) {
    animation-delay: -0.8s;
}

@keyframes typing {
    0%,
    60%,
    100% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
}

@media screen and (max-width: 960px) {
    .container {
        padding: 0 15px;
    }
}