@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap);
.Logo {
    display: flex;
    align-items: center;
}

.Logo__img {
    width: 160px;
    max-width: 100%;
    margin: 0 8px;
    text-align: center;
}

.Logo__img img {
    max-width: 100%;
    height: auto;
}

.Logo__text {
    font-size: 12px;
    text-transform: uppercase;
}
.CopyInput {
    position: relative;
}

.CopyInput__input {
    width: 100%;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    padding: 15px;
    padding-right: 45px;
    background: transparent;
}

.CopyInput__copy {
    width: 25px;
    height: 30px;
    background: url("/images//copy-icon.svg") center/cover no-repeat;
    border: none;
    outline: none !important;
    position: absolute;
    top: 13px;
    right: 15px;
    z-index: 1;
}

.CopyInput__alert {
    position: fixed;
    top: 80px;
    right: 40px;
    background: #ffffff;
    box-shadow: 4px 4px 20px rgba(1, 98, 187, 0.3);
    border-radius: 3px;
    font-size: 15px;
    font-weight: normal;
    color: #1d1d1d;
    border: none;
    padding: 12px 20px;
    padding-right: 60px;
}

.CopyInput__alert button {
    color: #0162bb !important;
    opacity: 1 !important;
}

.CopyInput__alert button>span {
    font-weight: 400;
}
.Widget {
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
}

.Widget__info {
    max-width: 580px;
    width: 100%;
}

.Widget__title {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 10px;
}

.Widget__data {
    display: flex;
    justify-content: space-between;
}

.Widget__value {
    font-weight: 700;
}

.Widget__value span {
    font-weight: 400;
}

.Widget__caption {
    font-size: 13px;
    margin-top: 10px;
    font-weight: 300;
}

.Widget__address {
    margin-top: 20px;
}

.Widget__description {
    font-size: 15px;
    margin-bottom: 5px;
}

@media screen and (max-width: 960px) {
    .Widget {
        padding: 15px;
        flex-direction: column;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
    }
    .Widget__info {
        max-width: 100%;
    }
    .Widget__title {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .Widget__data {
        flex-direction: column;
    }
    .Widget__cell {
        margin-bottom: 20px;
    }
    .Widget__caption {
        margin-top: 5px;
    }
    .Widget__address {
        margin-top: 0;
    }
    .Widget__qr {
        max-width: 184px;
        align-self: center;
        margin-top: 20px;
    }
}
.Timer {
    display: flex;
    align-items: center;
}

.Timer__text {
    margin-right: 20px;
    font-size: 12px;
    font-weight: 300;
}

.Timer__value {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.73);
    padding-left: 30px;
    background: url("/images/clock.svg") left center / 20px 20px no-repeat;
}
.Summary {
    background: #f2f2f2;
}

.Summary__header {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    border-bottom: 1px solid #dbdbdb;
}

.Summary__content {
    padding: 55px 0 100px 0;
}

.Summary__title {
    font-weight: 300;
    font-size: 36px;
    margin-bottom: 20px;
}

.Summary__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.Summary__widget {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 50px;
}

.Summary__instruction {
    padding-top: 30px;
}

.Summary__instruction-title {
    font-size: 26px;
    font-weight: 300;
}

.Summary__sub {
    display: block;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 20px;
}

.Summary__text {
    font-size: 15px;
    font-weight: 400;
}

.Summary__list {
    list-style-type: none;
    margin-left: 15px;
    margin-bottom: 30px;
}

.Summary__list-item {
    counter-increment: count-me;
    font-size: 15px;
    margin-bottom: 10px;
}

.Summary__list-item::before {
    content: counter(count-me) ". ";
    display: block;
    position: relative;
    max-width: 0px;
    max-height: 0px;
    left: -1.3em;
    top: 0.05em;
    color: #0162bb;
    font-weight: bold;
    font-size: 15px;
}

.Summary__services-title {
    font-size: 20px;
    font-weight: 400;
    background: url("/images/arrow.svg") left center / 15px 9px no-repeat;
    padding-left: 25px;
    margin-bottom: 20px;
}

.Summary__services-list {
    display: flex;
    justify-content: space-between;
}

.Summary__services-link {
    width: 100%;
    max-width: 295px;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    color: #000;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    padding: 21px 25px;
    transition: all 0.3s ease;
}

.Summary__services-link:hover {
    box-shadow: 2px 3px 6px rgba(1, 98, 187, 0.2);
    color: #0162bb;
    transition: all 0.3s ease;
    text-decoration: none;
}

.Summary__footer {
    background: #fff;
    border-top: 1px solid #dbdbdb;
}

.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
}

.Footer__text {
    font-size: 13px;
    font-weight: normal;
}

@media screen and (max-width: 960px) {
    .Summary__content {
        padding: 30px 0;
    }
    .Summary__title {
        font-size: 26px;
    }
    .Summary__widget {
        padding-bottom: 30px;
    }
    .Summary__instruction-title {
        font-size: 22px;
        margin-bottom: 5px;
    }
    .Summary__sub {
        margin-bottom: 5px;
    }
    .Summary__list {
        margin-left: 0;
        padding-left: 20px;
    }
    .Summary__services-list {
        flex-direction: column;
        align-items: center;
    }
    .Summary__services-link {
        margin-bottom: 10px;
        max-width: 100%;
    }
    .Footer {
        flex-direction: column;
        height: auto;
        padding: 10px 0;
    }
    .Footer__text {
        font-size: 13px;
        text-align: center;
        margin-top: 10px;
    }
}

@media screen and (max-width: 520px) {
    .Summary__status {
        flex-direction: column;
        align-items: flex-start;
    }
}
html,
body {
    font-family: "Ubuntu", sans-serif;
    color: #1d1d1d;
    font-size: 15px;
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.container {
    max-width: 980px;
    padding: 0 30px;
}

.Status__WaitingForPayment {
    display: flex;
}

.Status__loading span {
    -webkit-animation: typing 1s linear infinite;
            animation: typing 1s linear infinite;
}

.Status__loading span:nth-child(2) {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
}

.Status__loading span:nth-child(3) {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}

@-webkit-keyframes typing {
    0%,
    60%,
    100% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
}

@keyframes typing {
    0%,
    60%,
    100% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
}

@media screen and (max-width: 960px) {
    .container {
        padding: 0 15px;
    }
}
.payout-form{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-label {
    display: block;
}

.form-input {
}
